.footer {
    a.credit {
        color: $black;
        position: relative;
        &::after {
            content: "📪";
            position: absolute;
            top: 3px;
            right: -30px;
            opacity: 0;
            transform: translateY(-5px);
            animation: postflag 1.5s linear infinite alternate;
            transition: $trans;
        }
        &:hover {
            border-bottom: 1px solid $black;
            &::after {
                opacity: 1;
                transform: translateY(0px);
                // animation: postflag 2s linear infinite alternate;
            }
        }
    }
}

@keyframes postflag {
    0% {
        content: "📪";
    }
    25% {
        content: "📫";
    }
    50% {
        content: "📬";
    }
    75% {
        content: "📫";
    }
    100% {
        content: "📪";
    }
}
