* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
    &:before,
    &:after {
        content: "";
        content: none;
    }
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
img {
    max-width: 100%;
    height: auto;
}

li {
    list-style-type: none;
}
input {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
::selection {
    background: $grey;
    color: $black;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
address,
li {
    color: $black;
}

.ajax {
    min-height: 100vh;
}
img {
    opacity: 1;
    transition: opacity $ease 0.3s;
}
img[data-src] {
    opacity: 0;
}
img[data-src].loaded {
    opacity: 1;
}



/// 


// RESET
body {
	font: 21px/28px 'AB', sans-serif;
	color: #333;
	@include phone {
		font: 17px/22px 'AB', sans-serif;
	}
}

li { list-style-type: none; }
img { 
    // width: 100%; 
    height: auto; 
}



