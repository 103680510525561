// Utilities

// Body Utilities – Lock Scroll
.u-locked {
    overflow: hidden;
}

.u-hide {
    opacity: 0;
    pointer-events: none;
}

.u-link {
    border-bottom: 1px solid $black;
    color: $black;
    &:hover {
        border-bottom: 1px dotted $black;
    }
}

// only show on mobile/tablet
.u-mobile {
    display: block;
    @include md {
        display: none;
    }
}

// only show on desktop
.u-desktop {
    display: none;
    @include md {
        display: block;
    }
}

.u-center {
    display: flex;
    justify-content: center;
}

.u-portrait {
    @include sm {
        img {
            max-height: 75vh;
        }
    }
}

// Basic Ajax Transitions
main {
    transition: $trans;
    &.out {
        opacity: 0;
    }
}

// LOGO
svg {
	z-index: 5;
}

.st0 {
	z-index: 4;
	fill: #333333;
	transition: all $ease .4s;
	&.white {
		fill: #ffffff;
	}
}

.lettera, .letterb, .arrow {
	display: block;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all $ease .5s;
	&.off {
		top: 52%;
		opacity: 0;
	}
}


.lettera {
	transform: translate(-170%, -50%);
	@include phone {
		transform: translate(-130%, -50%) scale(.6);
	}
}

.letterb {
	transform: translate(100%, -50%);
	@include phone {
		transform: translate(50%, -50%) scale(.6);
	}
}

.arrow {
	opacity: 1;
	transform-origin: 50% 50%;
	transform: translate(-50%, -50%) rotate(-90deg);
	@include phone {
		transform: translate(-50%, -50%) rotate(-90deg) scale(.6);
	}
}




// LOCATIONS
.bottom-left {
	transform: translate(-50%, -50%);
	left: 8vw;
	top: calc(100vh - 15vh);
	@include phone {
		transform-origin: 50% 50%;
		transform: translate(-50%, -50%) scale(.6);
		left: 40px;
		top: calc(100% - 55px);
	}
}

.top-right {
	transform: translate(-50%, -50%);
	left: calc(100vw - 9vw);
	top: 9vw;
	@include phone {
		transform-origin: 50% 50%;
		transform: translate(-50%, -50%) scale(.6);
		left: calc(100% - 40px);
		top: 40px;
	}
}

.bottom-right {
	transform: translate(-50%, -50%);
	left: calc(100vw - 9vw);
	top: calc(100vh - 15vh);
	@include phone {
		transform-origin: 50% 50%;
		transform: translate(-50%, -50%) scale(.6);
		left: calc(100% - 50px);
		top: calc(100% - 55px);
	}
}

.anim {
    opacity: 0;
    transition: all $ease .6s;
    transform: translateY(10px);
}

.visible {
    opacity: 1;
    transform: translateY(0px);
}