
// NAV
header.nav {
	position: fixed;
	z-index: 1;
	padding: 5vw 5vw;
	nav {
		ul.menu {
			li.menu-item {
				display: inline-block;
				padding: 0 20px 0 0;
				opacity: 0;
				transform: translateY(-15px);
				transition: all $ease .5s;
				&.visible {
					transform: translateY(0px);
					opacity: 1;
				}
				a { 
					text-decoration: none;
					color: #333;
					position: relative;
					&::after {
						bottom: -10px;
						content: ' ';
						display: block;
						position: absolute;
						height: 2px;
						width: 100%;
						background: lighten(#333, 60%);
						transition: all $ease .4s;
						opacity: 0;
					}
					&:hover {
						&::after {
							opacity: 1;
							bottom: -3px;
						}
					}
				}
			}
		}
	}
}




