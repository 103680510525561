.main {
    padding: 80px 18px 18px;
    min-height: 100vh;
    position: relative;

    @include sm {
        padding: 15vh 17vw 12vh;
    }
}


.info {
    padding-bottom: 100px;
    @include sm {
        padding-bottom: 0px;
        padding-left: 50%;
    }
}


.images {
    figure {
        position: relative;
        margin-bottom: 20px;
        @include sm {
            margin-bottom: 40px;
        }
        // background: red;
        // &:nth-child(odd) {
        //     background: blue;
        // }
        img {
            display: block;
        }
    }

    .portrait {
        @include sm {
            height: 90vh;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-height: 100%;
            width: auto;
        }
    }
}