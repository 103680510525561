

@font-face {
    font-family: $custom;
    src: url('../fonts/DeckardAB-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/DeckardAB-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/DeckardAB-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/DeckardAB-Regular.woff') format('woff');
}



// Character Styles
// .bold {
//     font-weight: 700;
// }
// .regular {
//     font-weight: 400;
// }
// .italic {
//     font-style: italic;
// }
// .caps {
//     text-transform: uppercase;
// }
// .display {
//     text-transform: uppercase;
//     letter-spacing: -1px;
// }

// Defaults
// body {
//     font-family: $family;
//     @include interpolate(font-size, $sm, 16px, $xl, 18px);
//     line-height: 1.2em;
//     font-weight: 400;
// }

// a {
//     @include interpolate(font-size, $sm, 16px, $xl, 18px);
//     line-height: 1em;
// }

// p,
// address,
// li {
//     @include interpolate(font-size, $sm, 16px, $xl, 18px);
//     line-height: 1.5em;
// }

// strong {
//     @extend .bold;
// }

// h1 {
//     @include interpolate(font-size, $sm, 30px, $xl, 72px);
//     line-height: 1.2em;
// }

// h2 {
//     @include interpolate(font-size, $sm, 25px, $xl, 53px);
//     line-height: 1em;
// }

// h3 {
//     @include interpolate(font-size, $sm, 22px, $xl, 32px);
//     line-height: 1.2em;
// }

// h4 {
//     @include interpolate(font-size, $sm, 20px, $xl, 28px);
//     line-height: 1.2em;
// }

// h5 {
//     @include interpolate(font-size, $sm, 18px, $xl, 22px);
//     line-height: 1.2em;
// }

// h6 {
//     @include interpolate(font-size, $md, 16px, $xl, 21px);
//     line-height: 1.2em;
// }

// Paragraph Styles
// .t-nav {
//     @include interpolate(font-size, $sm, 16px, $xl, 18px);
//     @extend .bold, .caps;
// }

// .t-landing {
//     @include interpolate(font-size, $sm, 26px, $xl, 40px);
//     @extend .bold;
// }

// .t-grid {
//     @include interpolate(font-size, $sm, 18px, $xl, 20px);
// }

// .t-bigtext {
//     @include interpolate(font-size, $sm, 18px, $xl, 22px);
// }
// .t-text {
//     @include interpolate(font-size, $sm, 16px, $xl, 18px);
// }

// .t-smalltext {
//     @include interpolate(font-size, $sm, 14px, $xl, 16px);
// }
