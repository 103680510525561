// ABOUT / CONTACT

#about, #contact, #projects {
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	background: #898d80;
	position: fixed;
	z-index: 2;
	padding: 5vw 60vw 5vw 5vw;
	@include phone {
		padding: 20px 70px 20px 20px;
	}
	opacity: 0;
	pointer-events: none;
	transition: all $ease .4s;
	cursor: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzNHB4IiBoZWlnaHQ9IjM0cHgiIHZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojRkZGRkZGO308L3N0eWxlPjxnPjxnPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yNy40LDMxLjVsLTguNi04LjZjLTEtMS0yLjYtMS0zLjUsMGwtOC41LDguNWwtMy4xLTMuMWw4LjUtOC41YzEtMSwxLTIuNiwwLTMuNUwzLjUsNy42bDMuMS0zLjFsOC42LDguNmMxLDEsMi42LDEsMy41LDBsOC41LTguNWwzLjEsMy4xbC04LjUsOC41Yy0xLDEtMSwyLjYsMCwzLjVsOC42LDguNkwyNy40LDMxLjV6Ii8+PC9nPjwvZz48L3N2Zz4=), auto;

	&.visible {
		pointer-events: auto;
		opacity: 1;
	}

	p {
		color: white;
		margin-bottom: 15px;
	}
	a {
		color: white;
		text-decoration: none;
		position: relative;
		&::after {
			bottom: -3px;
			content: ' ';
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background: lighten(#333, 10%);
		}
	}
}

#contact {
	background: #edded1;
	cursor: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzNHB4IiBoZWlnaHQ9IjM0cHgiIHZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojMzMzMzMzO308L3N0eWxlPjxnPjxnPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yNy40LDMxLjVsLTguNi04LjZjLTEtMS0yLjYtMS0zLjUsMGwtOC41LDguNWwtMy4xLTMuMWw4LjUtOC41YzEtMSwxLTIuNiwwLTMuNUwzLjUsNy42bDMuMS0zLjFsOC42LDguNmMxLDEsMi42LDEsMy41LDBsOC41LTguNWwzLjEsMy4xbC04LjUsOC41Yy0xLDEtMSwyLjYsMCwzLjVsOC42LDguNkwyNy40LDMxLjV6Ii8+PC9nPjwvZz48L3N2Zz4=), auto;
	p, a {
		color: #333333;
	}
}

#projects {
    padding-right: 30vw;
    background: #dcd5d1;
    cursor: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzNHB4IiBoZWlnaHQ9IjM0cHgiIHZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojMzMzMzMzO308L3N0eWxlPjxnPjxnPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yNy40LDMxLjVsLTguNi04LjZjLTEtMS0yLjYtMS0zLjUsMGwtOC41LDguNWwtMy4xLTMuMWw4LjUtOC41YzEtMSwxLTIuNiwwLTMuNUwzLjUsNy42bDMuMS0zLjFsOC42LDguNmMxLDEsMi42LDEsMy41LDBsOC41LTguNWwzLjEsMy4xbC04LjUsOC41Yy0xLDEtMSwyLjYsMCwzLjVsOC42LDguNkwyNy40LDMxLjV6Ii8+PC9nPjwvZz48L3N2Zz4=), auto;
    ul.links {
        position: relative;
        z-index: 1;
        li {
            font-size: 40px;
            letter-spacing: -1px;
            line-height: 1.15em;
            @include sm {
                font-size: 70px;
                letter-spacing: -2px;
            }
            a {
                color: #333;
                transition: all $ease .5s;
                margin-left: 0px;
                &.out {
                    opacity: 0;
                    margin-left: 10px;
                }
                &.active {
                    &:after {
                        // opacity: 1;
                    }
                }
                &:after {
                    opacity: 0;
                    content: '';
                    width: 100%;
                    height: 4px;
                    background: #333;
                }
                &:hover {
                    color: white;
                }
            }
        }
    }


    ul.thumb {
        // background: red;
        position: absolute;
        right: 17vw;
        bottom: 12vh;
        width: 40%;
        height: 40%;
        display: block;
        padding-bottom: 0px;
        li {
            img {
                position: absolute;
                display: block;
                right: 0px;
                bottom: 0px;
                height: auto;
                line-height: 0;
                opacity: 0;
                transition: all ease .3s;
            }
            &.see {
                img {
                    @include sm {
                        opacity: 1;
                    }
                }
            }
        }
    }
}