// Variables

// Colours
$primary: rgba(0, 29, 149, 1);
$highlight: rgba(8, 239, 183, 1);
$secondary: rgba(254, 55, 106, 1);
$light-blue: rgba(204, 210, 232, 1);
$white: rgba(255, 255, 255, 1);
$grey: rgb(228, 228, 228);
$black: rgba(0, 0, 0, 1);

// Media Query Breakpoints
$xs: 480px;
$sm: 768px;
$md: 1024px;
$lg: 1440px;
$xl: 1600px;
$super: 2000px;

// Fonts
$custom: "AB";
$family: #{$custom}, "Helvetica Neue", Helvetica, Arial, sans-serif;

// Animation / Interpolation
$ease: cubic-bezier(0.59, 0.04, 0.36, 1);
$interpolateEase: "cubic-bezier(0.95, 0.05, 0.795, 0.035)";
$trans: all $ease 0.3s;
