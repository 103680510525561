
// SLIDER

.outerslider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
	.slider {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
		opacity: 0;
		transition: all $ease .5s;
		&.dim {
			opacity: .2;
		}
		&.visible {
			opacity: 1;
		}
		.slide {
			opacity: 0;
			position: absolute;
			top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
			width: 65%;
            transition: all $ease .7s;
            img {
                width: 100%;
            }
			@include phone {
				width: 90%;
			}
			@include desktop {
				width: 55%;
			}
			&.active {
				opacity: 1;
				pointer-events: none;
			}
		}

	}

}

